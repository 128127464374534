import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const RandomImage = () => {    
    const [imgIdx, setImgIdx] = React.useState(0);
    const fileCount = 7;

    React.useEffect(() => {
        setImgIdx(Math.floor(Math.random() * fileCount));
    }, []);

    switch (imgIdx) {
        case 0:
            return <div className="header-img">
                <StaticImage             
                    aspectRatio={7} layout="fullWidth"    
                    src="../images/header/DSC_0278.JPG"                     
                    id="hdrImg" alt="Header" />
            </div>            
        case 1:
            return <div className="header-img">
                <StaticImage             
                    aspectRatio={7} layout="fullWidth"    
                    src="../images/header/DSC_0140.JPG"                     
                    id="hdrImg" alt="Header" />
            </div>            
        case 2:
            return <div className="header-img">
                <StaticImage             
                    aspectRatio={7} layout="fullWidth"    
                    src="../images/header/DSC_0213.JPG"                     
                    id="hdrImg" alt="Header" />
            </div>            
        case 3:
            return <div className="header-img">
                <StaticImage             
                    aspectRatio={7} layout="fullWidth"    
                    src="../images/header/DSC_0202.JPG"                     
                    id="hdrImg" alt="Header" />
            </div>            
        case 4:
            return <div className="header-img">
                <StaticImage             
                    aspectRatio={7} layout="fullWidth"    
                    src="../images/header/DSC_0194.JPG"                     
                    id="hdrImg" alt="Header" />
            </div>            
        case 5:
            return <div className="header-img">
                <StaticImage             
                    aspectRatio={7} layout="fullWidth"    
                    src="../images/header/DSC_0151.JPG"                     
                    id="hdrImg" alt="Header" />
            </div>            
        case 6:
            return <div className="header-img">
                <StaticImage             
                    aspectRatio={7} layout="fullWidth"    
                    src="../images/header/DSC_0144.JPG"                     
                    id="hdrImg" alt="Header" />
            </div>            
        default: 
            return <div className="header-img">
                <StaticImage             
                    aspectRatio={7} layout="fullWidth"    
                    src="../images/header/DSC_0144.JPG"                     
                    id="hdrImg" alt="Header" />
            </div>             
    }
}

export default RandomImage;
