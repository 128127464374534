import * as React from "react"
import {useState} from "react"

import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Search from "../components/search"
import RandomImage from "../components/random-image"

import { useFlexSearch } from "react-use-flexsearch";

export const unFlattenResults = results =>
    results.map(post => {
        const { date, slug, tags, title, excerpt } = post;
        return { 
          except: excerpt,
          frontmatter: { title, date }, 
          fields: { slug, tags } 
        };
    });

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const { search } = typeof window !== "undefined" 
    ? window.location 
    : '';
  const query = new URLSearchParams(search).get('s')
  const [searchQuery, setSearchQuery] = useState(query || '');
  const {index, store} = data.localSearchPages;  

  const results = useFlexSearch(searchQuery, index, store);  

  const posts = searchQuery 
    ? unFlattenResults(results)
    : data.allMarkdownRemark.nodes;    

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>        
        <Bio />        
        <Search
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />      
        <p>
          No blog posts found.
        </p>
      </Layout>
    )
  }    

  return (
    <Layout location={location} title={siteTitle}>
      <RandomImage />
      <Bio />      
      <Search
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />      
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (            
            <li key={post.fields.slug}>              
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="All posts" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchPages {
      index
      store
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title          
        }
      }
    }
  }
`
