import * as React from "react"

const Search = ({ searchQuery, setSearchQuery }) => (
    <div className="search-box">
        <form 
            action="/"
            method="get"
            autoComplete="off"
        >        
            <input className="basic-text-input"
                value={searchQuery}
                onInput={(e) => setSearchQuery(e.target.value)}
                type="text"
                id="header-search"
                placeholder="Search Text"
                name="s"
            />        
        </form>
    </div>
);

export default Search;